import { useRef } from "react"
import QRCode from "react-qr-code"
import { useReactToPrint } from "react-to-print"

import Env from "classes/Env"
import { ITagPrintContent } from "interfaces/ITagPrintContent"
import AlertStyled from "theme/common/Alert.styled"
import { ButtonStyled } from "theme/common/Button.styled"
import { IconPrint } from "theme/common/Icon.styled"
import TagInfoCustomer from "./TagInfoCustomer"
import TagInfoCustomerStyled, { printStyled } from "./TagPrintContent.styled"
import Barcode from "components/Commons/BarCode"
import useGetSourceInfo from "hooks/useGetSourceInfo"
import { TypeVariation6 } from "types/CommonsTypes"
import { ShippingTypeEnum, DeliveryTypeEnum } from "enums/OrderEnum"
import { useEnv } from "hooks"

const TagPrintContent = ({ order, codigoEntrega, shippingGroupId, shippinGroupDetail }: ITagPrintContent) => {
  const [client] = useEnv().multiclient.get_user_env()
  const { sources } = useGetSourceInfo(order.storePickupId)
  const printElement = useRef<HTMLDivElement>(null)

  /* Reference field from wom*/
  const reference = order.shippingAddress?.location?.reference
    ? order.shippingAddress?.location?.reference
    : order.shippingAddress?.custom?.observation

  // TODO: Temporal. Remove this
  let address: string = order.shippingAddress?.address || ""
  let rut: string | TypeVariation6 = undefined
  const locationNumber: string = order.shippingAddress?.location?.number || ""
  const recibeTercero: string | TypeVariation6 =
    order.shippingAddress?.custom?.recibeTercero === "SI" ? order.shippingAddress?.custom?.recibeTercero : undefined
  let contactCustomName: string | TypeVariation6 = order.shippingAddress?.contact?.name

  if (client === Env.theme.wom.id && locationNumber !== "" && !address.includes(locationNumber)) {
    address = `${order.shippingAddress?.address} ${order.shippingAddress?.location?.number}`
  }
  
  if (client === Env.theme.wom.id && recibeTercero === "SI") {
    if (["televentaswm", "postventawm", "tiendas"].includes(order.channel)) {
      rut = order.shippingAddress?.custom?.rutTercero
      contactCustomName = `${order.shippingAddress?.custom?.nombreTercero || ""} ${
        order.shippingAddress?.custom?.apellidoTercero || ""
      }`
    } else {
      rut = order.shippingAddress?.contact?.custom?.RUT
      contactCustomName = `${order.shippingAddress?.contact?.name || ""}`
    }
  }

  const handelPrint = useReactToPrint({
    content: () => printElement.current,
    pageStyle: printStyled.toString(),
  })

  const sourceName = sources ? sources[0]?.name : ""

  return (
    <>
      <AlertStyled variant="info">ETIQUETA</AlertStyled>
      <TagInfoCustomerStyled ref={printElement}>
        <TagInfoCustomer
          customerName={order.customer?.name}
          rut={order.customer?.id}
          address={address}
          commune={order.shippingAddress?.location?.commune}
          communeName={order.shippingAddress?.location?.communeName}
          reference={reference}
          contactName={recibeTercero === "SI" ? "" : order.shippingAddress?.contact?.name}
          contactPhone={order.shippingAddress?.contact?.phone}
          portabilidad={order?.custom?.portabilidad?.toUpperCase()}
          courier={
            order?.shippingType === ShippingTypeEnum.HD ||
            (order?.shippingType === ShippingTypeEnum.SP && order?.deliveryType === DeliveryTypeEnum.SD)
              ? order?.additionalInformation?.couriers
              : null
          }
          channel={order?.channel}
          storePickupId={sourceName || order.storePickupId}
          contactRut={rut}
          contactCustomName={contactCustomName}
          recibeTercero={
            order?.custom?.portabilidad === "SI" && order.channel !== "tiendas" ? null : recibeTercero
          }
          tipoRetiro={
            shippinGroupDetail?.shippingType === "SP" && !shippinGroupDetail?.custom?.infoElocker?.isElocker ? "Mesón"
            : (shippinGroupDetail?.custom?.infoElocker?.isElocker ? "Locker" : (shippinGroupDetail?.shippingType === "HD" && shippinGroupDetail?.custom?.deliveryType === "ED" ? "Despacho Express" : null))
          }
        />
        <div className="secundary-section">
          {codigoEntrega ? (
            <div className="qr-code more-qr">
              <div>
                <QRCode value={codigoEntrega} size={170} />
                <div className="qr-details">
                  <span className="title-code">Código de ingreso</span>
                  <p>{codigoEntrega}</p>
                </div>
              </div>
              <div>
                <QRCode value={shippingGroupId} size={140} />
              </div>
            </div>
          ) : (
            <div className="qr-code">
              <div>
                <QRCode value={shippingGroupId} size={140} />
              </div>
            </div>
          )}

          <Barcode value={shippingGroupId} />

          <ButtonStyled variant="secondary" onClick={handelPrint}>
            <IconPrint />
            <span>Imprimir</span>
          </ButtonStyled>
        </div>
      </TagInfoCustomerStyled>
    </>
  )
}

export default TagPrintContent
